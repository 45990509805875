import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { createBenefit } from '../../../../../services';
import { SnackbarContext } from '../../../../../context';
import { checkToothFaces, dateToISO } from '../../../../../utils';
import { startLoading, stopLoading } from '../../../../../redux/slices';

export function useSubmit({
  hiddenView,
  budgetServiceid,
  onChange,
}: {
  hiddenView: () => void;
  budgetServiceid: number;
  onChange: () => any;
}) {
  const dispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: { [key: string]: any }) => {
    try {
      dispatch(startLoading());
      await axiosPromise(spec, budgetServiceid);
      showSuccessSnackbar('La prestación se ha creado con éxito');
      onChange();
      hiddenView();
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;
      showErrorSnackbar(message);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise(spec: { [key: string]: any }, budgetServiceid: number) {
  const apiSpec = transformSpec(spec);

  return createBenefit(apiSpec, budgetServiceid);
}

function transformSpec(spec: { [key: string]: any }) {
  const apiSpec = spec?.todos.map((benefit: { [key: string]: any }) => {
    return {
      periodo: benefit.period,
      fecha: dateToISO(benefit.date),
      id_prof: benefit.id_professional,
      id_paciente: benefit.id_patient,
      id_prestacion: benefit.id_benefit,
      id_usuario: benefit.id_user,
      id_sucursal: benefit.id_office,
      diente_sector: benefit.tooth_section,
      cara: benefit.tooth_faces
        ? Object.keys(checkToothFaces(benefit.tooth_faces))
            .join('')
            .toUpperCase()
        : null,
      es_usd: benefit.is_usd,
      importe_os: benefit.values_to_charge.from_medical_insurance,
      importe_pac: benefit.values_to_charge.from_patient,
      obs: '',
      comi_os: null,
      comi_pac: null,
      comi_os_imp: null,
      comi_pac_imp: null,
      solp: false,
      fecha_solp: null,
      liquidada: false,
      rechazada: false,
      pagada: false,
      fecha_liqui: null,
      fecha_recha: null,
      fecha_pago: null,
      id_liq_prof: null,
      id_liq_solp: null,
      ignorarAuditoria: true,
    };
  });

  return apiSpec;
}
